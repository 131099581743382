@import "../../styles/fonts.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.header {
	@include flex(row, space-between, center);
	position: fixed;
	top: 0;
	z-index: 3;
	width: 100vw;
	height: $header;
	background-color: #fff;
	box-shadow: 2px -2px 7px rgba(46, 53, 46, 0.808);
}

.h-menu {
	position: absolute;
	left: 0.5rem;
	z-index: 1;
	@include flex(row, center, center);
	width: 5.8rem;
	height: 5rem;
	padding: 1rem 1.5rem;
	@include surface {
		left: -10rem;
	}
	&__toggler {
		@extend .h-menu;
		z-index: 2;
		opacity: 0;
		//transition from h-menu to x
		//.h-menu > div are the hamburger menu
		&:checked + .h-menu > div {
			transform: rotate(135deg);
			&:before,
			&:after {
				transform: rotate(90deg);
				top: 0;
			}
		}
		//rotate X when hovered
		&:checked:hover + .h-menu > div {
			transform: rotate(225deg);
		}
		&:checked ~ .menu {
			transform: translate(0, 0);
			background-color: rgba(255, 255, 255, 0.8);
			backdrop-filter: blur(10px);
		}
		&:checked ~ .menu > ul {
			opacity: 1;
		}
	}
	&__lines {
		position: relative;
		width: 100%;
		height: 0.2rem;
		background-color: black;
		transition: all 0.4s ease-in-out;
		&:before,
		&:after {
			@extend .h-menu__lines;
			position: absolute;
			content: "";
			top: -0.8rem;
			z-index: 1;
		}
		&:after {
			top: 0.8rem;
		}
	}
}

.menu {
	@include flex(column, center, center);
	position: fixed;
	top: 0;
	right: 0rem;
	width: 100vw;
	height: 100vh;
	background-color: white;
	transition: transform 1s ease-in-out;
	transform: translate(0, -200%);
	@include surface {
		@include flex(row, flex-end, flex-end);
		position: static;
		height: auto;
		padding-right: 2rem;
		transform: translate(0, 0);
	}
	&__list {
		@include flex(column, center, center);
		margin-bottom: 3rem;
		padding: 0;
		list-style-type: none;
		transition: opacity 1s ease-in;
		opacity: 0;
		@include surface {
			flex-direction: row;
			margin: 0;
			opacity: 1;
		}
		&-item {
			margin: 3rem;
			padding-top: 2rem;
			@include font(4rem, 4.2rem, 300, #535353);
			text-transform: uppercase;
			transition: color 0.4s ease;
			@include surface {
				margin: 0;
				padding: 0 1.5rem;
				font-size: 1.8rem;
			}
		}
	}

	&__link {
		padding: 0.3rem 0;
		border-top: rgba(255, 255, 255, 0) 2px solid;
		border-bottom: rgba(255, 255, 255, 0) 2px solid;
		text-decoration: none;
		transition: 250ms ease-in-out;
		&:hover {
			@extend .menu__link--selected;
		}
		&--selected {
			border-bottom: rgb(132, 170, 149) 1px solid;
		}
	}
}
