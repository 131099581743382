@import "../../styles/variables.scss";
@import "../../styles/fonts.scss";
@import "../../styles/mixins.scss";

.connect-form {
	@include flex(column, center, center);
	width: 100%;
	height: 100%;
	min-height: 31rem;
	padding: 7.5%;
	background-color: #fff;
	@include desktop {
		padding-top: 4rem;
	}
	&__title {
		margin: 1rem 0;
		font-size: 1.7rem;
		text-align: center;
		color: #526957;
		@include breakpoint(380px) {
			font-size: 2.1rem;
			@include desktop {
				font-size: 2.8rem;
			}
		}
	}
	&__textbox {
		width: 100%;
		height: 4rem;
		margin: 0.5rem 0;
		padding: 0.5rem 2rem;
		outline: none;
		border: none;
		border-radius: 4px;
		background-color: rgba(163, 184, 163, 0.4);
		font-family: "Montserrat";
		font-size: 1.6rem;
		color: #526957;
		@include breakpoint(600px) {
			margin: 0.8rem 0;
			@include desktop {
			}
		}
		&:active {
			border-bottom: rgb(56, 87, 56);
			background-color: #fff;
		}
		&--2 {
			height: 100%;
			min-height: 8rem;
			font-family: "Montserrat";
			resize: none;
		}
	}
	&__button {
		@extend .connect-form__textbox;
		background-color: #58665b;
		font-size: 2rem;
		text-transform: uppercase;
		color: #fff;
	}
	&__success-msg {
		height: auto;
		font-size: 3rem;
		font-weight: 500;
		text-align: center;
		color: #fff;
	}
	&__error-msg {
		@extend .connect-form__textbox;
		height: auto;
		text-align: center;
		font-weight: 500;
	}
}
