//PRIMARY FONT:

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&family=Great+Vibes&family=Marck+Script&family=Satisfy&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arbutus+Slab&display=swap");

@font-face {
	font-family: "Archivo Black", sans-serif;
	src: url("../assets/fonts/ArchivoBlack/ArchivoBlack-Regular.ttf")
		format("truetype");
	font-weight: normal;
	font-display: block;
}

@font-face {
	font-family: "Bacalisties";
	src: url("../assets/fonts/bacalisties-font/Bacalisties-2OnXo.ttf")
		format("truetype");
	font-weight: normal;
	font-display: block;
}

@font-face {
	font-family: "Romantica";
	src: url("../assets/fonts/romantica-font/Romantica-RpXpW.ttf")
		format("truetype");
	font-weight: normal;
	font-display: block;
}

@mixin font($size, $height, $weight, $color) {
	font-size: $size;
	line-height: $height;
	font-weight: $weight;
	color: $color;
}
