@import "../../styles/fonts.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.rings {
	display: flex;
	justify-content: space-around;
	width: 90%;
	max-width: 85rem;
	margin: 2rem auto;
	padding: 1.5rem;
	border-radius: 0.5rem;
	background-color: rgba(255, 255, 255, 0.08);
	@include tablet {
		margin: 3rem auto;
		@include desktop {
			margin: 0 auto 2rem auto;
			width: 50%;
		}
	}
}

.percent {
	&,
	.percent__svg,
	.percent__circle {
		width: 8rem;
		height: 8rem;
		@include tablet {
			width: 16rem;
			height: 16rem;
		}
	}
	&__circle {
		position: absolute;
		fill: none;
		stroke-width: 5;
		stroke-dasharray: 220;
		stroke-linecap: round;
		transform: translate(0.5rem, 0.5rem);
		@include tablet {
			stroke-width: 10;
			stroke-dasharray: 440;
			transform: translate(1rem, 1rem);
		}
		&-grey {
			stroke-dashoffset: 0;
			stroke: #424242;
		}
		&-color--1 {
			stroke: salmon;
			animation: percent1 3.4s linear;
			transform: rotate(270deg) translate3d(-7.5rem, 0.5rem, 0px);
			@include tablet {
				animation: percent2 3.4s linear;
				transform: rotate(270deg) translate3d(-15rem, 1rem, 0px);
			}
		}
		&-color--2 {
			stroke: rgb(252, 231, 117);
			animation: percent1 3.4s linear;
			transform: rotate(270deg) translate3d(-7.5rem, 0.5rem, 0px);
			@include tablet {
				animation: percent2 3.4s linear;
				transform: rotate(270deg) translate3d(-15rem, 1rem, 0px);
			}
		}
	}
}

@keyframes percent1 {
	0% {
		stroke-dashoffset: 220;
	}
}

@keyframes percent2 {
	0% {
		stroke-dashoffset: 440;
	}
}

.ring {
	@include flex(column, space-between, center);
	width: 45%;
	min-height: 16rem;
	@include tablet {
		min-height: 28rem;
	}
	&__title {
		color: #fff;
		font-size: 1.8rem;
		font-weight: 500;
		@include tablet {
			font-size: 2.5rem;
		}
	}
	&__details {
		margin-bottom: 1rem;
		font-family: "Archivo Black", sans-serif;
		font-size: 1.3rem;
		font-weight: 300;
		@include tablet {
			font-size: 1.7rem;
			@include desktop {
				font-size: 2rem;
			}
		}
	}
	&__info {
		position: relative;
		bottom: 6rem;
		left: -1rem;
		width: 10rem;
		color: #fff;
		@include tablet {
			bottom: 11.5rem;
			left: -1.8rem;
			width: 20rem;
		}
	}
	&__percent {
		font-size: 1.4rem;
		font-weight: 300;
		text-align: center;
		@include tablet {
			font-size: 2.8rem;
		}
		&span {
			font-size: 2.4rem;
			font-weight: 200;
			opacity: 0.7;
		}
	}
	&__symbol-1 {
		color: salmon;
	}

	&__symbol-2 {
		color: rgb(252, 231, 117);
	}
}
