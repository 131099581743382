@import "../../styles/fonts.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.project-content__image {
	height: 30vh;
	margin: 1rem 3rem 1rem 0;
	border-radius: 1.5rem;
	box-shadow: -2px 2px 11px 0px #333333;
}
