@import "../../styles/fonts.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.connect-page {
	@include flex(row, center, center);
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100vw;
	overflow: hidden;
	&__content {
		@include flex(row, center, center);
		box-shadow: 1px 1px 10px 1px #747474;
		height: unquote("min(65vh,36rem)");
		width: 85vw;
		max-width: 48rem;
		background-color: #fff;
		@include breakpoint(600px) {
			width: 80vw;
			height: unquote("min(70vh,60vw)");
			max-width: 84rem;
			max-height: 48rem;
			@include desktop {
				width: 70vw;
				height: unquote("min(70vh,40vw)");
				max-height: 55rem;
				max-width: 97rem;
			}
		}
	}
	&__photo {
		display: none;
		@include fadeIn;
		@include breakpoint(600px) {
			display: initial;
			width: 43%;
			height: 100%;
			object-fit: cover;
			object-position: 50% 30%;
			filter: saturate(0.5);
		}
	}
	&__text-box {
		height: 100%;
		width: 100%;
		overflow-y: scroll;
		@include breakpoint(600px) {
			width: 57%;
		}
	}
	&__text {
		font-size: 1.7rem;
		font-weight: 500;
		text-align: center;
		color: #fff;
		@include surface {
			font-size: 2rem;
			@include tablet {
				font-size: 2.5rem;
			}
			@include breakpoint(900px) {
				font-size: 3.2rem;
				margin: 1rem;
			}
		}
		&--2 {
			margin-top: 78vw;
			color: rgb(63, 87, 63);
			@include breakpoint(600px) {
				margin: 1rem;
			}
		}
	}
	&__bg {
		position: absolute;
		z-index: -1;
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
		width: 100%;
		background-color: rgba(93, 128, 93, 0.5);

		&--dark {
			top: 0;
			height: calc(50% + #{$header} / 2);
			opacity: 1;
		}
		&--white {
			display: none;
		}
	}
}
