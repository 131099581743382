@import "../../styles/fonts.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.challenge-card {
	position: relative;
	top: -2rem;
	width: 95%;
	padding: 0 1.5rem;
	margin: 0 auto;
	height: calc(100vh - #{$header} - #{$footer});
	height: calc((var(--vh, 1vh) * 100) - #{$header} - #{$footer});
	max-width: 138rem;
	overflow: hidden;
	overflow-y: scroll;
	@include tablet {
		top: -3rem;

		width: 90%;
		height: calc(100vh - #{$header} - #{$footer});
		height: calc((var(--vh, 1vh) * 100) - #{$header} - #{$footer});
	}
	&__title {
		display: inline-block;
		margin-top: 2.5rem;
		font-family: "Archivo Black", sans-serif;
		color: rgb(43, 56, 43);
		font-size: 2.7rem;
		@include surface {
			font-size: 4rem;
			margin-top: 5rem;
			@include tablet {
				font-size: 5rem;
			}
		}
	}

	&__link-icon {
		display: inline-block;
		position: relative;
		margin: 0 1.5rem;
		height: 2.5rem;
		animation: iconShake 0.25s ease-in-out 0.45s 3;
		transition: all 0.45s;
		@include tablet {
			height: 3.5rem;
			@include desktop {
				height: 4rem;
			}
		}
		&:hover {
			transform: scale(1.3);
		}
	}
}

.challenge-content {
	width: 100%;
	@include flex(column, center, center);
	transition: opacity 0.3s;
	transition-timing-function: ease-in;
	@include desktop {
		@include flex(row, space-between, center);
	}
	&__info {
		@include flex(column, center, center);
		align-self: flex-start;

		@include desktop {
			margin-right: 3rem;
			width: 45vw;
		}
	}
	&__description {
		color: rgb(182, 182, 182);
		font-family: "Archivo Black", sans-serif;
		margin-bottom: 1rem;
		font-size: 1.4rem;
		@include surface {
			font-size: 2.5rem;
		}
	}
	&__challenge {
		font-size: 1.6rem;
		color: #333333;
		font-weight: 700;
		// text-transform: uppercase;
		@include surface {
			font-size: 2.7rem;
		}
	}
	&__example {
		max-width: 100%;
	}

	&__preview {
		cursor: pointer;
		overflow-x: scroll;
		display: flex;
		padding: 0 2rem;
		width: 100%;
		height: 35vh;
		margin-top: 2rem;
		@include desktop {
			width: 45vw;
			margin: 0;
		}
	}
	&__solution {
		width: 90%;
		margin: 2rem;
	}
}
