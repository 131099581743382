@import "../../styles/fonts.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.home__content {
	width: 100vw;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	padding-top: $header;
	padding-bottom: $footer;
	background-color: #f4f4f4;
}

.tech-stack {
	@include flex(column, center, center);
	width: 100vw;
	height: 100%;
	padding-top: 2rem;
	@include surface {
	}
	&__title {
		font-size: 4rem;
		margin-bottom: 3vh;
		font-family: "Bacalisties";
		color: #4e4e4e;
		text-align: center;
		font-weight: 300;

		@include surface {
			font-size: 5rem;
			letter-spacing: 3px;
			@include tablet {
				font-size: 6rem;
			}
		}
	}
	&__logos {
		width: 20rem;
		margin: 2rem auto;
		@include surface {
			width: 28rem;
			@include tablet {
				width: 33rem;
			}
		}
	}
	&__list {
		width: 95%;
		max-width: 40rem;
		border: solid 1px #b6b5b5;
		border-radius: 1rem;
		padding: 0.7rem 0rem;
		// padding-top: unquote("min(4%, 1.5rem)");
		padding-top: unquote("clamp(0.1rem, 2vh, 1.5rem)");
		position: relative;
		margin: unquote("min(1vh, 1rem)");
		box-shadow: 1px 1px 3px 0 #b6b5b5;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		@include breakpoint(350px) {
			padding: 0.7rem 2rem;
			@include tablet {
				max-width: 60rem;
				@include desktop {
					max-width: 80rem;
				}
			}
		}
	}
	&__list-name {
		position: absolute;
		top: -0.8rem;
		left: 2rem;
		font-size: 1.6rem;
		font-weight: 400;
		background-color: #f4f4f4;
		padding: 0 1rem;
	}
	&__icon {
		height: 5vh;
		max-height: 3.5rem;
		margin: 0rem 0.8rem;
		filter: grayscale(0.6);
		transition: all 60ms ease;
		max-width: unquote("min(25vw, 12rem)");
		object-fit: contain;
		&:hover {
			filter: none;
		}
		margin-bottom: unquote("min(2%, 0.5rem)");
		@include tablet {
			max-height: 6rem;
			height: 6vh;
			@include desktop {
				height: 7vh;
				max-height: 8rem;
			}
		}
	}
}
