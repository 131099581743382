@import "../../styles/fonts.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.project-card {
	position: relative;
	top: -2rem;
	width: 95%;
	padding: 0 1.5rem;
	max-width: 138rem;
	height: calc(100vh - #{$header} - #{$footer});
	height: calc((var(--vh, 1vh) * 100) - #{$header} - #{$footer});
	margin: 0 auto;
	// overflow: hidden;
	overflow-y: scroll;
	@include tablet {
		top: -3rem;
		width: 90%;
		@include desktop {
			height: calc(100vh - #{$header} - #{$footer});
			height: calc((var(--vh, 1vh) * 100) - #{$header} - #{$footer});
		}
	}

	&__title {
		display: inline-block;

		margin: 2.5rem 1rem 0 0;
		font-family: "Archivo Black", sans-serif;
		font-size: 3.1rem;
		color: rgb(43, 56, 43);
		@include surface {
			margin-top: 5rem;
			font-size: 5rem;
			@include tablet {
				margin-right: 1.5rem;
				font-size: 7rem;
				@include desktop {
					margin-right: 2rem;
					font-size: 8rem;
				}
			}
		}
	}
	&__link {
		:hover {
			height: 4rem;
			@include tablet {
				height: 5.5rem;
				@include desktop {
					height: 6rem;
				}
			}
		}
	}
	&__demo {
		display: inline-block;
		position: relative;
		margin: 0 0.5rem;
		height: 3rem;
		animation: iconShake 0.25s ease-in-out 0.45s 3;
		transition: all 0.45s;
		@include tablet {
			height: 4.5rem;
			@include desktop {
				height: 5rem;
			}
		}
		@keyframes iconShake {
			0% {
				transform: rotateZ(0deg);
			}
			25% {
				transform: rotateZ(20deg);
			}
			75% {
				transform: rotateZ(-20deg);
			}
			100% {
				transform: rotateZ(0deg);
			}
		}
	}
	&__code {
		@extend .project-card__demo;
	}
}

.project-content {
	@include flex(column, center, center);
	width: 100%;
	transition: opacity 0.7s;
	transition-timing-function: ease-in;
	padding-bottom: 1rem;
	@include desktop {
		@include flex(row, space-between, center);
	}
	&__info {
		@include flex(column, center, flex-start);
		align-self: flex-start;

		@include desktop {
			width: 45vw;
			margin-right: 3rem;
		}
	}
	&__info-description {
		margin-bottom: 1rem;
		font-family: "Archivo Black", sans-serif;
		font-size: 1.4rem;
		color: rgb(182, 182, 182);

		@include surface {
			font-size: 2.5rem;
		}
	}
	&__info-stack {
		@include flex(row, flex-start, center, wrap);
	}
	&__info-tech {
		display: inline;
		margin: 0.2rem;
		padding: 0.2rem 0.5rem;
		background-color: rgb(68, 71, 68);
		border-radius: 0.5rem;
		font-size: 1.2rem;
		font-weight: 300;
		color: white;
		@include surface {
			margin: 0.3rem;
			padding: 0.5rem 1rem;
			border-radius: 0.5rem;
			font-size: 1.5rem;
		}
	}

	&__preview {
		display: flex;
		width: 100%;
		height: 35vh;
		margin-top: 2rem;
		padding: 0 2rem;
		overflow-x: scroll;
		@include desktop {
			width: 45vw;
			margin: 0;
		}
	}
}

.button__showStack {
	display: block;
	margin: 1rem 0 1rem auto;
	padding: 0.3rem 0.5rem;
	border: none;
	border-radius: 4px;
	outline: none;
	background-color: rgba(255, 255, 255, 0.6);
	font-size: 1.4rem;
	text-align: right;
	color: rgb(68, 71, 68);
	cursor: pointer;
	transition: all 0.45s ease-in-out;
	&:hover {
		border: rgb(68, 71, 68) 1px solid;
		background-color: rgba(255, 255, 255, 1);
		font-style: italic;
	}
	@include surface {
		font-size: 2rem;
		height: 3rem;
	}
}
