@import "../../styles/variables.scss";
@import "../../styles/fonts.scss";
@import "../../styles/mixins.scss";

.hero {
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	background-color: #fff;
	@include surface {
		flex-direction: row;
	}
	&__img {
		height: 50%;
		object-fit: cover;
		@include fadeIn;
		@include surface {
			width: 50vw;
			height: 100%;
		}
		&--love {
			object-position: 50% 35%;
			@include surface {
				object-position: 80% 50%;
			}
		}
	}
	&__about {
		position: relative;
		@include flex(column, center, center);
		height: 50%;
		padding-bottom: calc(#{$footer} - 1rem);
		background-color: #fff;
		@include surface {
			width: 50vw;
			height: 100%;
		}
		&-box {
			width: 90vw;
			margin: 0 auto;
			position: relative;
			@include breakpoint(300px) {
				width: 80vw;
				@include surface {
					width: 40vw;
					height: 23rem;
					margin-top: 3rem;
					@include tablet {
						height: 29rem;
						@include desktop {
							width: 37vw;
							height: 31rem;
						}
					}
				}
			}
		}
	}

	&__title {
		font-family: "Montserrat";
		font-weight: 700;
		color: #526957;
		font-size: 4rem;
		@include tablet {
			font-size: 5.2rem;
		}
		@include desktop {
			font-size: 7rem;
		}
	}
	&__title--1 {
		font-family: "Romantica";
		margin: 0 0 -1.5rem 0;
	}
	&__info {
		font-size: 1.6rem;
		font-weight: 300;
		transition: opacity 1s ease-in;
		@include surface {
			width: 40vw;
			max-width: 55rem;
			margin-bottom: 0;
			@include tablet {
				width: 35vw;
				font-size: 2rem;
			}
		}
	}
	&__techstack-link {
		width: 3rem;
		right: 0;
		bottom: -4vh;
		position: absolute;
		animation: flash 1500ms infinite ease;
		cursor: pointer;
		@include surface {
			width: 4rem;
			bottom: -6vh;
			@include desktop {
				width: 5rem;
			}
		}
	}
}

@keyframes flash {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.profession {
	color: rgb(252, 116, 101);
	font-weight: 400;
}

.Typist .Cursor {
	display: inline-block;

	&--blinking {
		opacity: 1;
		animation: blink 800ms infinite;
		color: #526957;
		font-weight: 500;
		@keyframes blink {
			0% {
				opacity: 1;
			}
			50% {
				opacity: 1;
			}
			100% {
				opacity: 0;
			}
		}
	}
}
