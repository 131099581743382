@import "../../styles/fonts.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.work {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	padding-top: $header;
	background-color: rgb(50, 51, 50);
	perspective: 900px;
	perspective-origin: 50% -35%;
	overflow: hidden;
	@include surface {
		perspective-origin: 50% -25%;
	}
	&--stack {
		transition: transform 0.45s, opacity 0.45s;
		transition-timing-function: ease-in-out;
	}

	&__header {
		position: absolute;
		top: $header;
		@include flex(row, center, center);
		height: 30vh;
		width: 100vw;
	}
	&__title {
		transition: all 0.45s linear;
		font-family: "Romantica", "Monserrat";
		color: #fff;
		font-size: 4rem;
		@include breakpoint(400px) {
			font-size: 4.5rem;
			@include breakpoint(500px) {
				font-size: 5.5rem;
				@include tablet {
					font-size: 8rem;
					@include desktop {
						font-size: 9rem;
					}
				}
			}
		}
	}
}
