@import "../../styles/variables.scss";
@import "../../styles/fonts.scss";
@import "../../styles/mixins.scss";

.home {
	height: 200vh;
	height: calc(var(--vh, 1vh) * 200);
	padding: 0;
	background-color: rgb(50, 51, 50);
}
