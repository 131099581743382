@import "../../styles/fonts.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.event {
	@include breakpoint(900px) {
		transition: filter linear 0.1s;
		filter: grayscale(100%);
		&:hover {
			filter: grayscale(0%);
		}
	}
	&__year {
		display: none;
		font-weight: 400;
		@include surface {
			display: block;
			padding: 1rem;
			background-color: white;
			font-size: 3.5rem;
			text-align: center;
		}
	}
	&__box {
		@include flex(column-reverse, flex-start, center);
		width: 80%;
		margin: 0 auto 1.5rem auto;
		@include surface {
			flex-direction: row;
			background-color: initial;
			width: 90vw;
			max-width: 96rem;
			margin: 0 auto 3rem auto;
			&--1 {
				flex-direction: row-reverse;
			}
		}
	}

	&__details {
		margin-bottom: 1rem;
		@include surface {
			width: 50%;
			padding: 0 2rem;
		}
	}

	&__picture {
		width: 60%;
		max-width: 35rem;
		height: auto;
		margin: 1.5rem 0;
		object-fit: scale-down;
		@include moveRight(40px, linear, 2s);
		@include surface {
			width: 35%;
			margin: auto 2rem;
		}
		&--1 {
			@include moveLeft(40px, linear, 2s);
			@include surface {
				align-self: right;
			}
		}
	}
	&__time-interval,
	&__title {
		font-size: 1.5rem;
		text-align: center;
		font-weight: 600;
		color: #555555;
		@include surface {
			font-size: 1.6rem;
			text-align: right;
		}
		&--1 {
			@include surface {
				text-align: left;
			}
		}
	}

	&__info {
		margin-top: 1.5rem;
		font-size: 1.5rem;
		font-weight: 300;
		text-align: left;
		@include surface {
			margin: initial;
			text-align: right;
		}
		&--1 {
			text-align: left;
		}
		@include desktop {
			font-size: 1.7rem;
		}
	}
}
