@import "../../styles/fonts.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.work__footerBG {
	position: fixed;
	bottom: 0;
	width: 100vw;
	height: $footer;
	z-index: 20;
	transition: background-color 0.45s linear;
}
