@import "../../styles/fonts.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.journey {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	padding-top: $header;
	overflow-y: hidden;
	&__hero {
		@include flex(row, center, center);
		height: $journeyHeroMobile;
		padding: 0.1rem;
		background-color: rgba(93, 128, 93, 0.4);
		background-image: url(../../assets/images/journey-hero.JPG);
		background-position: center;
		background-size: cover;
		@include surface {
			height: $journeyHero;
		}
	}
	&__title {
		font-family: "Bacalisties", "Marck Script";
		font-size: 5rem;
		text-align: center;
		color: rgba(255, 255, 255, 0.7);
		@include surface {
			font-size: 7rem;
			@include tablet {
				font-size: 10rem;
			}
		}
	}
	&__footerBG {
		position: fixed;
		bottom: 0;
		width: 100vw;
		height: $footer;
		background-color: #fff;
	}
}

.timeline {
	position: relative;
	overflow-y: scroll;
	margin-top: 2rem;
	padding-top: 0.3rem;
	height: calc(100vh - #{$journeyHeroMobile} - #{$footer} - #{$header} - 2rem);
	height: calc(
		(var(--vh, 1vh) * 100) - #{$journeyHeroMobile} - #{$footer} - #{$header} - 2rem
	);
	@include surface {
		height: calc(100vh - #{$journeyHero} - #{$footer} - #{$header} - 2rem);
		height: calc(
			(var(--vh, 1vh) * 100) - #{$journeyHero} - #{$footer} - #{$header} - 2rem
		);
	}
	&__line {
		position: fixed;
		top: calc(#{$journeyHero} + #{$header} + 2.3rem);
		left: 50%;
		z-index: -1;
		width: 5px;
		height: calc(100vh - #{$journeyHeroMobile} - #{$footer} - #{$header} - 5rem);
		height: calc(
			(var(--vh, 1vh) * 100) - #{$journeyHeroMobile} - #{$footer} - #{$header} - 5rem
		);
		border-radius: 5px;
		@include surface {
			height: calc(100vh - #{$journeyHero} - #{$footer} - #{$header} - 5rem);
			height: calc(
				(var(--vh, 1vh) * 100) - #{$journeyHero} - #{$footer} - #{$header} - 5rem
			);
			background-color: #cccccc;
		}
	}
}
