@import "../../styles/fonts.scss";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.project {
	position: absolute;
	width: 100%;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	padding: 0.1rem 1rem;
	overflow: hidden;
	background-color: rgb(85, 97, 85);
	border-radius: 2px;
	box-shadow: 2px -2px 7px rgba(46, 53, 46, 0.808);
	transition: transform 0.45s, opacity 0.45s;
	transition-timing-function: ease-in-out;
	&:first-child {
		position: absolute;
	}
	&:nth-child(2n + 1) {
		background-color: rgb(107, 126, 107);
	}
	&.project--selected {
		position: relative;
		box-shadow: none;
		cursor: default;
	}
	&__title {
		height: calc(#{$header} + 1rem);
		margin: 0 3rem;
		padding: 0.5rem 0;
		font-size: 2rem;
		font-weight: 400;
		text-align: right;
		color: #fff;
		cursor: pointer;
		@include surface {
			height: calc(#{$header} + 2rem);
			font-size: 3rem;
		}
	}
}
