@import "../src/styles/fonts.scss";
@import "../src/styles/variables.scss";

*,
*:before,
*:after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: black;
}

html,
body {
	overflow-x: hidden;
	scroll-behavior: smooth;
	margin: 0;
	width: 100%;
	font-size: 62.5%;
	font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

.portfolio {
	width: 100vw;
	height: 100vh;
	padding-top: 0.1rem;
}
