@import "../../styles/variables.scss";
@import "../../styles/fonts.scss";
@import "../../styles/mixins.scss";

.footer {
	@include flex(row, center, center);
	position: fixed;
	width: 100vw;
	height: 6rem;
	padding: 0.4rem 1rem;
	@include surface {
		justify-content: space-between;
	}
	&__signature {
		display: none;
		text-shadow: 1px 1px 3px rgb(107, 126, 107), -1px -1px 3px rgb(107, 126, 107);
		@include surface {
			display: initial;
			position: fixed;
			left: 2%;
			bottom: 0;
			height: 5.5rem;
			font-family: "Romantica", "Monserrat";
			font-size: 3rem;
		}
	}
}

.connect {
	@include flex(row, space-between, center);
	position: fixed;
	bottom: 0%;
	width: 20rem;
	height: 6rem;
	height: unquote("min(8.5vh,6rem)");
	@include surface {
		right: 2%;
		width: 18rem;
	}
	&__item {
		list-style: none;
	}
	&__link {
		text-decoration: none;
	}

	&__icon {
		width: 3rem;
		width: unquote("clamp(2.5rem,4.5vh,3rem)");
	}
}
